export const getRenewalMessage = (renewalDate) => {
  const today = new Date();
  const renewDate = new Date(renewalDate);
  const diffTime = renewDate - today;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

  if (diffDays === 0) {
    return { message: "(Renew Today)", className: "dayleftcls" };
  } else if (diffDays < 0) {
    const absDiffDays = Math.abs(diffDays);
    const months = Math.floor(absDiffDays / 30);
    const days = absDiffDays % 30;
    if (months > 0) {
      return {
        message: `(Expired ${months} month${months > 1 ? "s" : ""} ${days} day${
          days !== 1 ? "s" : ""
        } ago)`,
        className: "expiredago",
      };
    } else {
      return {
        message: `(Membership Expired ${absDiffDays} days ago)`,
        className: "expiredago",
      };
    }
  } else if (diffDays <= 10) {
    return {
      message: `(${diffDays} days left)`,
      className: "dayleftcls",
    };
  } else {
    return { message: "", className: "" };
  }
};

export const formatTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  let hours12 = hours % 12 || 12; // Convert to 12-hour format, with 0 as 12
  let period = hours < 12 ? "AM" : "PM";

  return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
};
